<template>
  <div>
    <div class="pageMain">
      <el-form :model="searchForm" :inline="true" ref="searchForm" label-position="left" class="demo-form-inline" v-if="searchButton == '1'">
        <!-- <el-form-item label="单据日期：">
          <el-date-picker v-model="time" :default-time="['00:00:00','23:59:59']" @change="changeTime" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="选择日期" prop="endTime">
          <el-date-picker @change="changeDate($event,'searchForm')" v-model="searchForm.endTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
          <!-- <el-date-picker v-model="time" @change="changeTime"
              value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker> -->
        </el-form-item>
        <el-form-item label="选择产品">
          <goods-change ref="goodsChange" @popupClick="getPopupGoods($event,'searchForm')" @goodsSelectEvent="goodsDataEvent($event,'searchForm')"></goods-change>
        </el-form-item>
        <el-form-item label="产品类型">
          <el-cascader :options="goodsType" :show-all-levels="false" v-model="goodsTypeId" placeholder="请选择产品类型" :props="{
            checkStrictly: true,
            label: 'name',
            value: 'id',
            children: 'childs',
          }" clearable @change="cascaderChange"></el-cascader>
        </el-form-item>
        <el-form-item label="仓库：">
          <el-select v-model="changeWarehouseId" multiple placeholder="请选择仓库" clearable>
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否有库存：">
          <el-select v-model="searchForm.type" placeholder="请选择仓库" clearable>
            <el-option v-for="(item, index) in numType" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序：">
            <el-select v-model="searchForm.sort" placeholder="请选择仓库" clearable>
              <el-option v-for="(item, index) in sortData" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchSubmit('searchForm')" icon="el-icon-search">搜索</el-button>
          <el-button @click="resetForm('searchForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="userTable boxMain">
        <p class="boxTitle">产品库存余额表</p>
        <div class="tableTopBtn">
          <el-button size="mini" type="primary" @click="exportData" v-if="exportButton == '1'" :disabled="tableData.length == 0">导出</el-button>
        </div>
        <template>
          <el-table ref="multipleTable" height="50vh" :data="tableData" show-summary :summary-method="getSummaries" tooltip-effect="dark" style="width: 100%;" v-loading="loading">
            <!-- <el-table-column prop="code" label="产品编码" width="100" align="center" fixed="left">
            </el-table-column> -->
            <el-table-column prop="goodsName" label="名称" width="150" align="center" fixed="left">
              <template slot-scope="scope">
                <!-- <router-link :to="{ name: 'receiveDetailed', params: { goodsId: scope.row.id } }" style="color:#409EFF">{{ scope.row.goodsName }}</router-link> -->
                <router-link :to="{ name: 'receiveDetailed', params: { goodsName: scope.row.goodsName } }" style="color:#409EFF">{{ scope.row.goodsName }}</router-link>
              </template>
            </el-table-column>
            <el-table-column prop="category" label="产品类别" width="200" align="center">
            </el-table-column>
            <el-table-column prop="standard" label="规格" align="center">
            </el-table-column>
            <el-table-column prop="unit" label="单位" align="center">
            </el-table-column>
            <el-table-column label="全部仓库" align="center">
              <el-table-column prop="num" label="单位数量" align="center">
              </el-table-column>
              <el-table-column prop="totalNum" label="商品总量" align="center">
                <template slot-scope="scope">
                  {{scope.row.type== 0 ?'':scope.row.totalNum}}
                </template>
              </el-table-column>
              <el-table-column prop="price" label="单位成本" align="center">
                <template slot-scope="scope">{{ scope.row.price }} 元</template>
              </el-table-column>
              <el-table-column prop="compCost" label="成本小计" align="center" min-width="120px">
                <template slot-scope="scope">{{ scope.row.compCost }} 元</template>
              </el-table-column>
            </el-table-column>
            <el-table-column v-for="(item,index) in warehouseData" :key="index" :label="item.warehouseName" align="center">
              <el-table-column prop="warehouseId" label="单位数量" align="center">
                <template slot-scope="scope">
                  <p v-for="(tItem,i) in scope.row.warehouseRelDTOList" :key="i">
                    <span v-if="item.warehouseId == tItem.warehouseId">{{ tItem.num }}</span>
                  </p>
                </template>
              </el-table-column>
              <el-table-column prop="totalNum" label="商品总量" align="center">
                <template slot-scope="scope">
                  <p v-for="(tItem,i) in scope.row.warehouseRelDTOList" :key="i">
                    <span v-if="item.warehouseId == tItem.warehouseId && scope.row.type !=0">{{ tItem.totalNum }}</span>
                  </p>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
          <div class="block" style="padding: 10px 15px">
            当前第 {{ pagesData.currentPage }} 页，共 {{ pagesData.pages }} 页 {{ pagesData.total }} 条
            <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagesData.currentPage" :page-sizes="pagesData.rows" :page-size="pagesData.currentRows" layout="total, sizes, prev, pager, next, jumper" :total="pagesData.total">
            </el-pagination> -->
          </div>
        </template>
      </div>

    </div>
    <!-- 导出 -->
    <el-dialog title="库存收发汇总表导出" :visible.sync="exportShow" width="30%" @close="exportPrintTotalShow = false;">
      <el-form :model="exportForm" ref="exportReceiveSummary" label-width="120px">
        <el-form-item label="选择日期" prop="endTime">
          <el-date-picker @change="changeDate($event,'exportForm')" v-model="exportForm.endTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="选择产品">
          <goods-change ref="goodsChange" @popupClick="getPopupGoods($event, 'exportForm')" @goodsSelectEvent="goodsDataEvent($event, 'exportForm')"></goods-change>
        </el-form-item>
        <el-form-item label="仓库：">
          <el-select v-model="exportForm.warehouseId" placeholder="请选择仓库">
            <el-option v-for="(item, index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否有库存：">
          <el-select v-model="exportForm.type" clearable placeholder="请选择是否有库存">
            <el-option v-for="(item, index) in numType" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div style="text-align:center;padding-top:20px">
        <el-button type="primary" @click="exportSubmit">导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goodsChange from "../../components/goodsChange.vue";
export default {
  data() {
    return {
      time: [],
      loading: true,
      //搜索
      searchForm: {
        goodsName:'',
        goodsId: '',
        warehouseId: [],
        categoryId: '',
        startTime:'',
        endTime:'',
      },
      changeWarehouseId:[],
      tableData: [],
      warehouseData: [],
      multipleTable: [],
      ids: [],
      searchButton: '',
      adcode: sessionStorage.getItem('adcode'),
      driverList: [],
      warehouseList: [],
      pagesData: {
        pages: 0,
        total: 0,
        currentPage: 1,
        currentRows: 1000,
        rows: [1000, 5000, 10000, 100000],
      },
      exportShow: false,
      exportForm: {},
      exportButton: '',
      sumCompCost: '',
      sumNum: '',
      totalSumNum: '',
      lazyFlag: true,
      numType: [
        {
          name: '全部',
          id: ''
        },
        {
          name: '有库存',
          id: '2'
        }, {
          name: '无库存',
          id: '1'
        },
      ],
      // 产品类型
      goodsType: [],
      // 类型ID回显
      goodsTypeId: [],
      sortData:[
        {
          name:'库存从小到大',
          id:0
        }, {
          name: '库存从大到小',
          id: 1
        },
      ]
    }
  },
  components: {
    goodsChange
  },
  created() {

    var privilege = JSON.parse(sessionStorage.getItem('authority'));
    privilege.forEach((item) => {
      if (item.authority == 'warehouse_query') {
        this.searchButton = '1'
        this.exportButton = '1'
      }
    });
  },
  mounted() {
    this.getSelectGoods()
    this.getDriverList()
    this.getWarehouse()
    this.getSumTotal()
    // this.listEvent()
    this.getGoodsType();
    // 表格懒加载
    let table = this.$refs.multipleTable.$el;

    table.addEventListener('scroll', (res) => {
      if (Number((res.target.scrollTop).toFixed(2)) + res.target.clientHeight >= res.target.scrollHeight - 20) {
        if (this.lazyFlag == true && this.tableData.length < this.pagesData.total && this.pagesData.currentPage < this.pagesData.pages) {
          this.$set(this.pagesData, 'currentPage', this.pagesData.currentPage + 1)
          this.listEvent();
          this.lazyFlag = false
        }
      }
    }, true)
  },
  activated() {
    this.$refs.multipleTable.doLayout()
  },
  methods: {
    changeDate(e,formName){
      console.log(e)
      if(formName == 'searchForm'){
        this.$set(this[formName], 'endTime', e)
      }else{
        this.$set(this[formName], 'endTime', e)
        // this.$set(this[formName], 'endTime', e + ' 23:59:59')
      }
    },
    getWarehouse() {
      let that = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouse/getWarehouse", {
          params: {},
        })
        .then(function (res) {
          if (res.data.errcode == 0) {
            that.warehouseList = res.data.data;
            let warehouseId = []
            res.data.data.forEach(item=>{
              warehouseId.push(item.id)
            })
            that.changeWarehouseId = warehouseId
            
            that.listEvent()
          }
        });
    },
    renderHeader(column, index) {
      console.log(column, index, "column, index")
    },
    // 获取司机下拉列表
    getDriverList() {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/driver/getDriver")
        .then((res) => {
          if (res.data.errcode == 0) {
            _this.driverList = res.data.data;
          }
        });
    },
    goSalesDelivery(row) {
      this.$router.push({ name: "salesReturn", params: row })
    },
    //合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        this.warehouseData.forEach(item => {
          if (column.property == item.warehouseId) {
            sums[index] = item.num.toFixed(2)
          }
        })
        if (column.property == "num") {
          sums[index] = Number(this.sumNum).toFixed(2);
        }
        if (column.property == "totalNum") {
          sums[index] = Number(this.totalSumNum).toFixed(2);
        }
        if (column.label == "成本小计") {
          sums[index] = Number(this.sumCompCost).toFixed(2) + ' 元'
        }
        // if (column.property == "num" || column.label == "成本小计") {
        //   const values = data.map(item => Number(item[column.property]));
        //   if (!values.every(value => isNaN(value))) {
        //     sums[index] = values.reduce((prev, curr) => {
        //       const value = Number(curr);
        //       if (!isNaN(value)) {
        //         return prev + curr;
        //       } else {
        //         return prev;
        //       }
        //     }, 0);
        //     sums[index] = sums[index].toFixed(2);
        //   } else {
        //     //   sums[index] = 'N/A';
        //   }
        // }

      });

      return sums;
    },
    getSelectGoods(warehouseId) {
      let _this = this;
      this.$axios
        .get(this.$axios.defaults.basePath + "/goodsMall/getList", {
          params: {
            warehouseId,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = JSON.parse(JSON.stringify(res.data.data))
            list.forEach(item => {
              item.name = item.name + ' ' + item.standard;
            });
            _this.exportGoods = list;
          }
        });
    },
    changeTime(e) {
      this.searchForm.startTime = e[0]
      this.searchForm.endTime = e[1]
    },
    // 获取列表统计数据
    getSumTotal(){
      let _this = this
      this.searchForm.size = _this.pagesData.currentRows
      this.searchForm.current = _this.pagesData.currentPage
      this.$axios.get(_this.$axios.defaults.basePath + '/warehouseGoodsRelStatistics/goodsRealDetailSummary', {
        params: _this.searchForm
      }).then(function (res) {
        _this.warehouseData = res.data.data.goodsMallSumByWarehouseList;
        _this.sumCompCost = res.data.data.sumCompCost
        _this.sumNum = res.data.data.sumNum
        _this.totalSumNum = String(res.data.data.totalSumNum)
      })
    },
    listEvent() {
      //获取列表数据
      var _this = this;
      this.loading = true
      this.searchForm.size = _this.pagesData.currentRows
      this.searchForm.current = _this.pagesData.currentPage
      this.$set(this.searchForm, 'warehouseId', this.changeWarehouseId.join(','))
      this.$axios.get(_this.$axios.defaults.basePath + '/warehouseGoodsRelStatistics/goodsRealDetail', {
        params: _this.searchForm
      }).then(function (res) {
        if (res.data.errcode == 0) {
          _this.loading = false;
          
          if (_this.tableData.length == 0) {
            _this.tableData = res.data.data.goodsMallSummaryDTOList.records;
          } else {
            _this.tableData = _this.tableData.concat(res.data.data.goodsMallSummaryDTOList.records);
          }
          _this.pagesData.total = res.data.data.goodsMallSummaryDTOList.total;
          _this.pagesData.pages = res.data.data.goodsMallSummaryDTOList.pages;
          _this.tableData.forEach((item, index) => {
            item.num = 0;
            item.totalNum = 0;
            item.type = Number(item.type);
            item.warehouseRelDTOList.forEach((child, i) => {
              item.num += Number(child.num)
              item.totalNum += Number(child.totalNum)
            })
          })
          if (_this.lazyFlag == false && _this.pagesData.currentPage == _this.pagesData.pages) {
            _this.$message({
              message: '已经是最后一页了',
              type: 'info'
            })
          }
          _this.$nextTick(() => {
            _this.lazyFlag = true
            _this.$refs.multipleTable.doLayout()
          })
          
        } else {
          _this.$message({
            message: res.data.errmsg,
            type: 'warning'
          });
        }

      })
    },
    //搜索操作
    searchSubmit() {
      if (!this.searchForm.goodsId) {
        let goodsName = this.$refs.goodsChange.searchName;
        this.$set(this.searchForm, 'goodsName', goodsName)
      }
      this.$set(this.pagesData, 'currentPage', 1)
      this.tableData = []
      this.getSumTotal()
      this.listEvent()
    },
    //表单重置
    resetForm() {
      Object.keys(this.searchForm).forEach(key => { this.searchForm[key] = '' })
      this.$refs.goodsChange.goodsName = ''
      this.$set(this.searchForm, 'goodsName', '')
      this.$set(this.searchForm, 'startTime', '')
      this.$set(this.searchForm, 'endTime', '')
      this.$set(this.searchForm, 'type', '')
      this.$set(this.searchForm, 'sort', '')
      this.$set(this.searchForm, 'startTime', '')
      this.$set(this.pagesData, 'currentPage', 1)
      this.tableData = []
      this.goodsTypeId = ''
      this.changeWarehouseId = []
      this.getSumTotal()
      this.listEvent()
    },
    handleSelectionChange(val) {
      var _this = this;
      _this.ids = [];
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        _this.ids.push(item.id);
      });
    },

    getPopupGoods(data, formName) {
      this.$set(this[formName], 'goodsId', data.id)
    },
    goodsDataEvent(data, formName) {
      let goodsIds = [];
      let names = []
      data.forEach(item => {
        goodsIds.push(item.id)
        names.push(item.goodsName)
      })
      this.$refs.goodsChange.goodsName = names.join(',')
      this.$set(this[formName], 'goodsId', goodsIds.join(','))
    },
    //分页--每页条数切换
    handleSizeChange(val) {
      this.pagesData.currentRows = val;
      this.listEvent();
    },
    //第几页
    handleCurrentChange(val) {
      this.pagesData.currentPage = val;
      this.listEvent();
    },
    exportData() {
      this.exportShow = true;
    },
    exportSubmit() {
      let _this = this;
      // console.log(_this.exportForm)
      // return;
      this.$axios
        .get(this.$axios.defaults.basePath + "/warehouseGoodsRelStatistics/goodsRealDetailExport", {
          params: _this.exportForm,
          responseType: 'blob'
        })
        .then((res) => {
          console.log(res)
          var eleLink = document.createElement("a");
          eleLink.download = "库存余额表.csv";
          eleLink.style.display = "none";

          var blob = new Blob([res.data]);
          eleLink.href = URL.createObjectURL(blob);
          console.info(blob);
          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          _this.exportShow = false;
          _this.$message({
            message: "导出成功",
            type: "success",
          });
          _this.exportForm = {};
          // 然后移除
          document.body.removeChild(eleLink);
        })

    },
    // 获取产品类型
    getGoodsType() {
      let _this = this;
      this.$axios
        .get(
          _this.$axios.defaults.basePath +
          "/goodsCategory/selectGoodsCategoryTree"
        )
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            _this.goodsType = res.data.data;
          } else {
            _this.$message({
              message: res.data.errmsg,
              type: "warning",
            });
          }
        });
    },
    // 多级联动change事件
    cascaderChange(e) {
      this.searchForm.categoryId = e[e.length - 1];
    },
  }
}
</script>

<style  scoped>
.orderId {
  color: #409eff;
  cursor: pointer;
}
.boxMain >>> .el-table .el-table__footer-wrapper tr td .cell {
  font-weight: 600;
}
</style>